<template>
 <TabBasic
  v-if="order"
  v-model:activeKey="activeKey"
  color="#ffffff"
  tabPosition="top"
 >
  <Child :key="1">
   <template #tab>
        <span>
          <sdFeatherIcons size="18" type="shopping-bag"/>
          Informatie
        </span>
   </template>
   <div>
    <a-row :gutter="25" type="flex">
     <a-col :md="12" :xs="24" style="display: flex;flex-flow: column;">
      <sdCards title="Informatie">
       <a-row :gutter="30">
        <a-col :md="12" :xs="24" class="mb-25">
         <InfoWrapper>
          <div class="info-title">Naam</div>
          <div class="info-text">
           <template v-if="order.relationRevision.type==='CONSUMER'">
            <span>{{
              order.relationRevision.gender === 'MALE' ? 'Dhr.' : ''
             }}{{
              order.relationRevision.gender === 'FEMALE' ? 'Mevr.' : ''
             }} {{
              order.relationRevision.firstName
             }}{{
              order.relationRevision.middleName && order.relationRevision.middleName !== '' ? ` ${order.relationRevision.middleName}` : ''
             }} {{ order.relationRevision.lastName }}</span>
           </template>
           <template v-if="order.relationRevision.type==='BUSINESS'">
            <sdFeatherIcons size="14" type="briefcase"/>
            <span>{{ order.relationRevision.companyName }}<br/>{{
              order.relationRevision.companyCocNumber
             }}</span>
           </template>
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Adres</div>
          <div class="info-text"> {{ order.relationRevision.street }} {{
            order.relationRevision.houseNumber
           }}{{
            order.relationRevision.houseNumberAddition && order.relationRevision.houseNumberAddition !== '' ? order.relationRevision.houseNumberAddition : ''
           }}<br/>{{ order.relationRevision.postalCode }} {{
            order.relationRevision.city
           }} {{ order.relationRevision.country === 'NL' ? 'Nederland' : '' }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Status</div>
          <div class="info-text">{{ order.statusName }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order.externalReference">
          <div class="info-title">Uitgaande ID</div>
          <div class="info-text">{{ order.externalReference }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order.subStatusName">
          <div class="info-title">Substatus</div>
          <div class="info-text">{{ order.subStatusName }}</div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Datum aangemaakt</div>
          <div class="info-text">{{ moment(order.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Datum geupdate</div>
          <div class="info-text">{{ moment(order.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order.expiryTimestamp" :key="order.isSigned">
          <div class="info-title">Contract tekendatum</div>
          <div class="info-text">{{ moment(order.signTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order.expiryTimestamp">
          <div class="info-title">Verloopt op</div>
          <div class="info-text">{{ moment(order.expiryTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order.isCancelled">
          <div class="info-title">Datum geannuleerd</div>
          <div class="info-text">{{
            moment(order.cancellationTimestamp).format('DD-MM-YYYY HH:mm:ss')
           }}
          </div>
         </InfoWrapper>
         <InfoWrapper v-if="order.isCancelled && order.cancellationBy">
          <div class="info-title">Geannuleerd door</div>
          <div class="info-text">{{ order.cancellationBy }}<br/><small>{{ order.cancellationIP }}</small>
          </div>
         </InfoWrapper>
         <InfoWrapper v-if="order.isSent">
          <div class="info-title">Datum verzonden</div>
          <div class="info-text">{{ moment(order.sentTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order.isSent && order.sentBy">
          <div class="info-title">Verzonden door</div>
          <div class="info-text">{{ order.sentBy }}<br/><small>{{ order.sentIP }}</small></div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Agent</div>
          <div class="info-text">{{ order.userFullName }}</div>
         </InfoWrapper>
        </a-col>
        <a-col :md="12" :xs="24" class="mb-25">
         <InfoWrapper>
          <div class="info-title">Email</div>
          <div class="info-text">
           {{ order.relationRevision.email }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Telefoonnummer</div>
          <div class="info-text">
           {{ order.relationRevision.phoneNumber }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Product</div>
          <div class="info-text">
           {{ order.productRevision.name }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Consument / Zakelijk</div>
          <div class="info-text">{{
            order.relationRevision.type === 'CONSUMER' ? 'Consument' : ''
           }}{{ order.relationRevision.type === 'BUSINESS' ? 'Zakelijk' : '' }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Type</div>
          <div class="info-text">{{
            order.signType === 'ESIGNATURE' ? 'Offerte' : ''
           }}{{ order.signType === 'SIGNATURE' ? 'Overeenkomst' : '' }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Flow</div>
          <div class="info-text">{{
            order.type === 'ENERGY' ? 'Energie' : ''
           }}{{ order.type === 'SVM' ? 'SVM subsidie' : '' }}{{ order.type === 'SHOP' ? 'Shop' : '' }}
          </div>
         </InfoWrapper>
         <InfoWrapper>
          <div class="info-title">Organisatie</div>
          <div class="info-text">{{ order.organizationName }}</div>
         </InfoWrapper>
         <InfoWrapper v-if="order?.extraData?.depositAmount && order?.extraData?.depositAmount > 0">
          <div class="info-title">Aanbetaald bedrag</div>
          <div class="info-text">{{
            new Intl.NumberFormat('nl-NL', {
             style: 'currency',
             currency: 'EUR',
             minimumFractionDigits: 2,
             maximumFractionDigits: 2,
            }).format(
             order?.extraData?.depositAmount
            )
           }}
          </div>
         </InfoWrapper>
        </a-col>
       </a-row>
      </sdCards>
     </a-col>
     <a-col :md="12" :xs="24" style="display: flex;flex-flow: column;">
      <sdCards title="Acties">
       <a-row :gutter="20">
        <a-col v-if="order && !order.isSigned" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-button class="btn-signin w-100" size="large" type="primary"
                    @click.prevent="emit('handleAction','SHOWOFFERDOCUMENT')"> Aanbod inzien
          </a-button>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canChangeStatus" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-button class="btn-signin w-100" size="large" type="primary"
                    @click.prevent="emit('handleAction','CHANGESTATUS')"> Status aanpassen
          </a-button>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canRegenerateContract" :lg="24" :md="24" :xl="24" :xs="24"
               :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-popconfirm
           cancel-text="Nee"
           ok-text="Ja"
           placement="leftTop"
           title="Weet je het zeker?"
           @confirm="emit('handleAction','REGENERATECONTRACT')"
          >
           <a-button :loading="loadingPdfRegenerateOrder" class="btn-signin w-100" size="large" type="primary">
            Contract hergenereren
           </a-button>
          </a-popconfirm>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canResendSignSMS" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-popconfirm
           cancel-text="Nee"
           ok-text="Ja"
           placement="leftTop"
           title="Weet je het zeker?"
           @confirm="emit('handleAction','OFFERSMS')"
          >
           <a-button class="btn-signin w-100" size="large" type="primary"> SMS verzenden
           </a-button>
          </a-popconfirm>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canResendConfirmationMail" :lg="24" :md="24" :xl="24" :xs="24"
               :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-popconfirm
           cancel-text="Nee"
           ok-text="Ja"
           placement="leftTop"
           title="Weet je het zeker?"
           @confirm="emit('handleAction','CONFIRMATIONMAIL')"
          >
           <a-button class="btn-signin w-100" size="large" type="primary"> Bevestiging opnieuw
            verzenden
           </a-button>
          </a-popconfirm>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canResendSignEmail" :lg="24" :md="24" :xl="24" :xs="24"
               :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-popconfirm
           cancel-text="Nee"
           ok-text="Ja"
           placement="leftTop"
           title="Weet je het zeker?"
           @confirm="emit('handleAction','OFFERMAIL')"
          >
           <a-button class="btn-signin w-100" size="large" type="primary"> Aanbod opnieuw verzenden
           </a-button>
          </a-popconfirm>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canSendExternal" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-popconfirm
           cancel-text="Nee"
           ok-text="Ja"
           placement="leftTop"
           title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
           @confirm="emit('handleAction','SENDEXTERNAL')"
          >
           <a-button :loading="loadingSendExternalOrder" class="btn-signin w-100" size="large" type="primary">
            Verzenden naar leverancier
           </a-button>
          </a-popconfirm>
         </div>
        </a-col>
        <a-col v-if="order && order.abilities.actions.canSignManual" :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-popconfirm
           cancel-text="Nee"
           ok-text="Ja"
           placement="leftTop"
           title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
           @confirm="emit('handleAction','SIGNMANUAL')"
          >
           <a-button class="btn-signin w-100" size="large" type="primary"> Manueel tekenen
           </a-button>
          </a-popconfirm>
         </div>
        </a-col>
        <a-col v-if="order && (order.abilities.actions.canCancel || order.abilities.actions.canCancelAtSupplier)"
               :lg="24" :md="24" :xl="24" :xs="24" :xxl="12"
               class="mb-5">
         <div class="sDash_form-action">
          <a-button class="btn-signin w-100" size="large" type="danger"
                    @click.prevent="emit('handleAction','CANCEL')"> Annuleren
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </a-col>
    </a-row>
    <a-row v-if="order.productRevision.subType === 'CHARGINGSTATION'" :gutter="25">
     <a-col :xs="24">
      <sdCards title="Winkelmandje">
       <ProductTable>
        <div class="table-cart table-responsive">
         <a-table :columns="productTableColumns" :dataSource="productTableData" :loading="loadingOrderSubProducts"
                  :pagination="false"/>
        </div>
        <!--        <div style="text-align: end;padding-right: 140px;padding-top:20px"><span class="cart-single-t-price">Totaal: {{-->
        <!--          new Intl.NumberFormat('nl-NL', {-->
        <!--           style: 'currency',-->
        <!--           currency: 'EUR'-->
        <!--          }).format(orderSubProducts)-->
        <!--         }}-->
        <!--             </span></div>-->
       </ProductTable>
      </sdCards>
     </a-col>
    </a-row>
    <a-row :gutter="25">
     <a-col :xs="24">
      <sdCards v-if="orderSubProducts && orderSubProducts?.data && orderSubProducts?.data?.length > 0"
               title="Producten">
       <template v-for="(p,i) in orderSubProducts?.data" :key="i">
        <a-row v-if="p.quantity>0"
               :gutter="30"
               style="padding-right:20px;padding-bottom:10px">
         <a-col :xs="16" class="mb-1">
          <label class="bold">{{ p.name }}</label>
         </a-col>
         <a-col :xs="8" class="mb-1" style="text-align:right">
          <a-row :gutter="30">
           <a-col :xs="10">
            <label class="bold">Aantal:</label>
           </a-col>
           <a-col :xs="14">
            <label style="font-weight: bold; font-size: 110%">{{ p.quantity }}</label>
           </a-col>
          </a-row>
          <a-row :gutter="30" style=" margin-top:  20px">
           <a-col :xs="10">
            <label class="bold">Totaal excl. btw:</label>
           </a-col>
           <a-col :xs="14">
            <label style="font-weight: bold; font-size: 110%">&euro;{{ p.totalAmount - p.vatAmount }}</label>
           </a-col>
           <a-col :xs="10">
            <label class="bold">btw:</label>
           </a-col>
           <a-col :xs="14">
            <label style="font-weight: bold; font-size: 110%">&euro;{{ p.vatAmount }}</label>
           </a-col>
           <a-col :xs="24">
            <hr>
           </a-col>
           <a-col :xs="10">
            <label class="bold">Totaal incl. btw:</label>
           </a-col>
           <a-col :xs="14">
            <label style="font-weight: bold; font-size: 110%">&euro;{{ p.totalAmount }}</label>
           </a-col>
          </a-row>
         </a-col>
         <a-col :xs="24">
                              <span class="status-text"
                                    style="color:#3262ff;background-color: #dfe0fd">bruto {{
                                p.subProduct.description.split('-')[0]
                               }} kWh</span>
          <span class="status-text ml-4"
                style="color:#3262ff;background-color: #dfe0fd">netto {{
            p.subProduct.description.split('-')[1]
           }} kWh kWh</span>
         </a-col>
        </a-row>
       </template>
      </sdCards>
     </a-col>
    </a-row>
    <a-row :gutter="25">
     <a-col :xs="24">
      <sdCards title="Documenten">
       <FilesListTable @showPdfViewer="emit('showPdfViewer')"/>
       <a-row class="mt-6">
        <a-col :sm="12" :xs="24">
         <div class="sDash_form-action">
          <a-button class="btn-signin" size="large" type="primary" @click.prevent="emit('showFileUpload')">
           Toevoegen
          </a-button>
         </div>
        </a-col>
       </a-row>
      </sdCards>
     </a-col>
    </a-row>
    <a-row v-if="flowQuestions && flowQuestions.length > 0" :gutter="25">
     <a-col :xs="24">
      <sdCards title="Productvragen">
       <a-form :model="{flowQuestions:flowQuestions}" layout="vertical" @finish="emit('UpdateFlowQuestions')">
        <a-row :gutter="25">
         <a-col v-for="(item,index) in flowQuestions" :key="index" :md="12" :xs="24">
          <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                       :name="['flowQuestions', index, 'value']"
                       :rules="{required: true,message: 'Dit veld is verplicht'}">
           <DatePickerWrapper>
            <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                           :disabled="order && !order.abilities.actions.canSendExternal"
                           :disabled-date="disabledStartDate"
                           :format="dateFormat" class="w-100"/>
           </DatePickerWrapper>
          </a-form-item>
          <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                       :name="['flowQuestions', index, 'value']"
                       :rules="{required: true,message: 'Dit veld is verplicht'}">
           <DatePickerWrapper>
            <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()"
                           :disabled="order && !order.abilities.actions.canSendExternal" :format="dateFormat"
                           class="w-100"/>
           </DatePickerWrapper>
          </a-form-item>
          <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                       :name="['flowQuestions', index, 'value']"
                       :rules="{required: true,message: 'Dit veld is verplicht'}">
           <a-input v-model:value="item.value" :disabled="order && !order.abilities.actions.canSendExternal"
                    placeholder=""/>
          </a-form-item>
          <a-form-item v-if="item.inputType === 'INTEGER'" :label="item.displayName"
                       :name="['flowQuestions', index, 'value']"
                       :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                       onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
           <a-input v-model:value="item.value" :disabled="order && !order.abilities.actions.canSendExternal"
                    placeholder="" step="1"
                    type="text"/>
          </a-form-item>
          <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                       :name="['flowQuestions', index, 'value']"
                       :rules="{required: true,message: 'Dit veld is verplicht'}">
           <a-select v-model:value="item.value" :allowClear="true"
                     :disabled="order && !order.abilities.actions.canSendExternal"
                     class="sDash_fullwidth-select" size="large">
            <a-select-option v-for="(option,index) in item.inputValues"
                             :key="index"
                             :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
            </a-select-option>
           </a-select>
          </a-form-item>
         </a-col>
        </a-row>
        <a-row>
         <a-col :sm="12" :xs="24">
          <div class="sDash_form-action">
           <a-button :disabled="loadingPatchOrderFlowQuestions || order && !order.abilities.actions.canSendExternal"
                     :loading="loadingPatchOrderFlowQuestions" class="btn-signin" html-type="submit"
                     size="large"
                     type="primary">
            Opslaan
           </a-button>
          </div>
         </a-col>
        </a-row>
       </a-form>
      </sdCards>
     </a-col>
    </a-row>
    <a-row :gutter="25">
     <a-col :md="12" :xs="24">
      <TimelineBoxWrap>
       <sdCards title="Activiteit">
        <a-timeline mode="alternate">
         <a-timeline-item v-for="(item,index) in activity" :key="index">
          <template #dot>
           <SwEllipse color="#ADB4D2" size="10"/>
          </template>
          <h2><small>{{ moment(item.timestamp).format('DD-MM-YYYY HH:mm:ss') }} -
           {{ item.userFullName }}</small></h2>
          <div class="content-box">
           <div>
            {{ item.description }}
           </div>
          </div>
         </a-timeline-item>
        </a-timeline>
       </sdCards>
      </TimelineBoxWrap>
     </a-col>
     <a-col :md="12" :xs="24">
      <TimelineBoxWrap>
       <sdCards title="Communicatie">
        <a-timeline mode="alternate">
         <a-timeline-item v-for="(item,index) in communication" :key="index">
          <template #dot>
           <SwEllipse color="#ADB4D2" size="10"/>
          </template>
          <h2><small>{{ moment(item.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }} -
           {{ item.userFullName }}</small></h2>
          <div class="content-box">
           <div>
                            <span class="mb-5">
                              {{ item.text }}
                            </span>
            <StepsStyle :current="item.currentStep" :status="item.failReason ? 'error' : ''"
                        class="mt-5" direction="vertical" size="small" step="direction">
             <a-step v-for="item in item.steps" :key="item.id" :description="item.description"
                     :title="item.title"/>
            </StepsStyle>
           </div>
          </div>
         </a-timeline-item>
        </a-timeline>
       </sdCards>
      </TimelineBoxWrap>
     </a-col>
    </a-row>
   </div>
   <sdCards>
    <a-row>
     <a-col :sm="12" :xs="24">
      <div class="sDash_order-action">
       <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Child>
  <Child v-if="order && order.abilities.tabs.relation" :key="2">
   <template #tab>
        <span>
          <sdFeatherIcons size="18" type="users"/>
          Relatie
        </span>
   </template>
   <p v-if="order">
    <Relation
     :can-update-relation="order.abilities.actions.canUpdateRelation"
     :enable-birthday="false"
     :order-id="orderId"
     :relation="order.relationRevision"
     :show-card-save-button="true"
     :show-footer="false"
     :show-global-save-button="false"
     :showIBAN="false"
     type="ORDEREDIT"
    ></Relation>
   </p>
   <sdCards>
    <a-row>
     <a-col :sm="12" :xs="24">
      <div class="sDash_order-action">
       <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Child>
  <Child v-if="order && order.abilities.tabs.script" :key="4">
   <template #tab>
        <span>
          <sdFeatherIcons size="18" type="file-text"/>
          Script
        </span>
   </template>
   <div v-if="script">
    <sdCards title="Voicelog">
     <a-row :gutter="30">
      <a-col :sm="4" :xs="24" class="mb-25" style="width: 100%;min-width: 100%;">
       <div v-html="script"></div>
      </a-col>
     </a-row>
    </sdCards>
    <sdCards>
     <a-row>
      <a-col :sm="12" :xs="24">
       <div class="sDash_order-action">
        <sdButton class="btn-signin" type="light" @click="$router.replace('/order')">
         Terug
        </sdButton>
       </div>
      </a-col>
     </a-row>
    </sdCards>
   </div>
  </Child>
 </TabBasic>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import VueTypes from "vue-types";
import {Child, TabBasic} from "@/components/tabs/Style";
import {useRoute} from "vue-router";
import moment from "moment";
import {InfoWrapper, TimelineBoxWrap} from "@/view/styled";
import Relation from "@/components/relation/Relation";
import {SwEllipse} from "@/components/utilities/icons";
import {integerValidator} from "@/utility/validators";
import {useStore} from "vuex";
import {FigureCart, StepsStyle} from "@/view/orders/Style";
import FilesListTable from "./FilesTable";

const productTableColumns = [
 {
  title: 'Product',
  dataIndex: 'product',
  key: 'product',
 },
 {
  title: 'Aantal',
  dataIndex: 'quantity',
  key: 'quantity',
 },
 {
  title: 'Prijs',
  dataIndex: 'total',
  key: 'total',
  width: '150px'
 },
 {
  title: '',
  dataIndex: 'action',
  key: 'action',
  width: '50px'
 },
];

const OrderDetailShop = defineComponent({
 name: 'OrderDetailShop',
 components: {
  TabBasic,
  Child,
  InfoWrapper,
  Relation,
  TimelineBoxWrap,
  SwEllipse,
  StepsStyle,
  FilesListTable,
 },
 emits: ['showPdfViewer', 'handleAction', 'showFileUpload', 'UpdateFlowQuestions'],
 data() {
  return {
   dateFormat: 'DD-MM-YYYY',
  }
 },
 props: {
  order: VueTypes.object.def(null),
  script: VueTypes.object.def(null),
  activity: VueTypes.array.def([]),
  communication: VueTypes.array.def([]),
  flowQuestions: VueTypes.array.def([]),
 },
 setup(props, {emit}) {
  const {params} = useRoute();
  const orderId = params.id;
  const activeKey = ref(1);
  const {state, dispatch} = useStore();
  const loadingPatchOrderFlowQuestions = reactive(computed(() => state.order.loadingPatchOrderFlowQuestions));
  const loadingSendExternalOrder = reactive(computed(() => state.order.loadingSendExternalOrder));
  const loadingPdfRegenerateOrder = reactive(computed(() => state.order.loadingPdfRegenerateOrder));
  const orderSubProducts = reactive(computed(() => state.shop.orderSubProducts));
  const loadingOrderSubProducts = reactive(computed(() => state.shop.loadingOrderSubProducts));

  const getDefaultPicker = () => {
   if (props.order && props.order.signTimestamp) {
    return moment(new Date(props.order.signTimestamp));
   }
  };

  const disabledStartDate = (current) => {
   if (props.order && props.order.signTimestamp) {
    const endDate = moment(props.order.signTimestamp).add(props.order.productRevision.energySwitchWindowMaxDays > 0 ? props.order.productRevision.energySwitchWindowMaxDays : 90, 'd')
    // const startDate = moment(props.order.signTimestamp).add(props.order.productRevision.energySwitchWindowMinDays > 0 ? (props.order.productRevision.energySwitchWindowMinDays - 1) : 39, 'd')
    return !current.isBetween(props.order.signTimestamp, endDate);
   }

   return true;
  };

  const productTableData = computed(() => {
    return orderSubProducts.value.data.map(product => {
     console.log(product)
     const {id, subProduct, name, quantity, totalAmount, externalReference} = product;
     return {
      key: id,
      product: (
       <div class="cart-single">
        <FigureCart>
         {subProduct.imageFileURL !== null ? (
          <img src={subProduct.imageFileURL} alt="" style="height:100%;width:80px;"/>
         ) : (
          <img alt="" style="height:100%;width:80px;"
               src="https://shop.eismann.nl/static/de.eismann.shop.theme/dist/img/product/no-image-nl.jpg"/>
         )}
         <figcaption style="width:100%">
          <div class="cart-single__info">
           {subProduct.externalReference !== null ? (
            <div>
             <span><small>Artikelnr.: {externalReference}</small></span><br/>
             <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
            </div>
           ) : (
            <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
           )}
          </div>
         </figcaption>
        </FigureCart>
       </div>
      ),
      quantity: (
       <div class="cart-single-quantity" style="min-width: 110px;">
        {quantity}
       </div>
      ),
      total: <span class="cart-single-t-price">{new Intl.NumberFormat('nl-NL', {
       style: 'currency',
       currency: 'EUR'
      }).format((parseFloat(totalAmount)))}</span>,
     };
    });
   }
  );

  onMounted(async () => {
   await dispatch('getOrderSubProducts', params.id);
  });

  return {
   orderId,
   activeKey,
   moment,
   integerValidator,
   emit,
   loadingPatchOrderFlowQuestions,
   getDefaultPicker,
   disabledStartDate,
   loadingSendExternalOrder,
   loadingPdfRegenerateOrder,
   productTableColumns,
   productTableData,
   loadingOrderSubProducts,
   orderSubProducts,
  }
 }
});

export default OrderDetailShop;
</script>
